<template>
  <nps-app
    v-if="token"
    :key="keyNPS"
    :url-api="urlApi"
    :token-nps="token"
    :configs="role"
    :token-comunicante="tokenComunicante"
    :tab-inicial="$route.params.aba"
    :fingerprint="fingerprint"
    class="opine"
  ></nps-app>
</template>

<script>
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import config from "@/core/config/layout.config.json";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import getBrowserFingerprint from "get-browser-fingerprint";

export default {
  name: "nps",
  data() {
    return {
      token: null,
      urlApi: null,
      role: null,
      tokenComunicante: JwtService.getToken(),
      keyNPS: 1,
      fingerprint: ""
    };
  },
  mounted() {
    this.hideChatwoot();
    this.hideHeader();
    this.fingerprint = getBrowserFingerprint();
    let link = document.createElement("link");

    //link.setAttribute("href", "https://opine-dev.wellon.digital/css/app.css");
    link.setAttribute("href", "https://opine.w3si.com.br/css/app.css");
    // link.setAttribute("href", "http://localhost:3000/css/app.css");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("id", "cssNps");
    link.async = true;
    document.head.appendChild(link);

    let script = document.createElement("script");
    //script.setAttribute("src", "https://opine-dev.wellon.digital/js/app.js");
    script.setAttribute("src", "https://opine.w3si.com.br/js/app.js");
    // script.setAttribute("src", "http://localhost:3000/js/app.js");
    script.setAttribute("id", "appNps");
    script.async = true;
    document.head.appendChild(script);

    this.inicializar();
  },
  watch: {
    "$route.params.aba"(atual) {
      this.hideHeader();
      this.updateNPS();
    }
  },
  methods: {
    inicializar() {
      const contaId = this.$route.query.contaId;
      const url = contaId ? "nps?contaId=" + contaId : "nps";
      ApiService.get("nps").then((response) => {
        this.urlApi = response.data.data.url;
        this.role = response.data.data.role;
        this.token = response.data.data.token;
      });
    },
    hideChatwoot() {
      if (window.$chatwoot) {
        try {
          const exapnded_text = document.getElementById(
            "woot-widget--expanded__text"
          );
          if (exapnded_text) exapnded_text.style.display = "none";
          const widget_bubble_icon = document.getElementById(
            "woot-widget-bubble-icon"
          );
          if (widget_bubble_icon) widget_bubble_icon.style.margin = "14px";
          const widget_bubble = document.querySelector(".woot-widget-bubble");
          if (widget_bubble) widget_bubble.style.height = "unset";
        } catch (error) {
          console.log(error);
        }
      }
    },
    showChatwoot() {
      if (window.$chatwoot) {
        try {
          const exapnded_text = document.getElementById(
            "woot-widget--expanded__text"
          );
          if (exapnded_text) exapnded_text.style.display = "flex";
          const widget_bubble_icon = document.getElementById(
            "woot-widget-bubble-icon"
          );
          if (widget_bubble_icon)
            widget_bubble_icon.style.margin = "14px 8px 14px 16px";
          const widget_bubble = document.querySelector(".woot-widget-bubble");
          if (widget_bubble) widget_bubble.style.height = "48px";
        } catch (error) {
          console.log(error);
        }
      }
    },
    hideHeader() {
      let newConfig = JSON.parse(JSON.stringify(config));
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "NPS" }]);
      this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
      newConfig.header.self.display = false;
      newConfig.subheader.display = false;
      newConfig.styleContetnt = { padding: "0" };
      newConfig.styleContainer = { "max-width": "100%", margin: 0 };
      newConfig.wrapper = { "padding-top": 0 };
      newConfig.scrollTop.self.display = false;
      newConfig.aside.self.minimize.hoverable = true;
      this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);
    },
    updateNPS() {
      this.keyNPS++;
    }
  },
  beforeDestroy() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
    var elcss = document.getElementById("cssNps");
    if (elcss) elcss.remove();
    var elapp = document.getElementById("appNps");
    if (elapp) elapp.remove();
    this.showChatwoot();
  }
};
</script>

<style>
.opine
  .v-progress-circular--indeterminate:not(.v-progress-circular--visible)
  > svg,
.opine
  .v-progress-circular--indeterminate:not(.v-progress-circular--visible)
  .v-progress-circular__overlay {
  animation-play-state: running !important;
}
.opine .statistics {
  width: 100%;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  font-weight: 400 !important;
  font-size: 13px !important;
  text-transform: initial !important;
  font-family: Poppins, Helvetica, "sans-serif" !important;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  font-size: 19.5px !important;
}
.opine div.flex.xs12.md3:first-of-type {
  padding-left: 0px !important;
}
#kt_brand {
  flex: none !important;
}
#kt_aside_menu {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}
</style>
